<!-- System: STA
    Purpose: User can add manual time and see his/her report
            of that pirticular Tasks/Activities
-->

<template>
  <!-- Add Manual Time Modal -->
  <div>
    <v-dialog
      v-model="addManualTaskModal"
      width="600"
      @click:outside="onHidden()"
    >
      <v-card id="modal-addOrg">
        <v-card-title class="headline white lighten-2 justify-center">
          Add Manual Time
          <v-spacer></v-spacer>
          <v-btn icon @click="(addManualTaskModal = false), onHidden()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <!-- Tabs For Manual Time And Reports -->
        <v-tabs v-model="selectedTab">
          <v-tab key="Time" @click="(addTimeTab = true), (reportsTab = false)"
            >Add Time</v-tab
          >
          <v-tab
            key="Reports"
            @click="
              getManualTimeList(), (addTimeTab = false), (reportsTab = true)
            "
            >Reports</v-tab
          >
        </v-tabs>
        <!-- Add Manual Time Reports Form -->
        <v-form @submit.prevent="onSubmit" v-show="addTimeTab">
          <v-row class="mt-8 px-2" v-if="task">
            <v-col cols="12" md="5" class="add-manual-time">
              <!-- Time Input Field -->
              <v-text-field
                class="ml-3 time-input"
                min="0"
                outlined
                label="Enter minutes"
                type="number"
                v-model="form.minutes"
                onKeyDown="javascript: return (((event.keyCode >= 48) && (event.keyCode <= 57)) || event.keyCode === 8 ||
                     event.keyCode === 46) ? true : !isNaN(Number(event.key))"
              ></v-text-field>
            </v-col>
            <!-- Member Name Input Field -->
            <v-col cols="12" md="6" class="add-manual-time">
              <div style="display: none">
                {{ (form.member_id = task.members.id) }}
              </div>
              <v-text-field
                id="member-name"
                class="ml-3 input-text"
                min="0"
                type="text"
                v-model="task.members.name"
                label="Members"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Reason Text Area -->
          <v-row>
            <v-col cols="12" >
              <div class="form-group mx-5">
                    <v-textarea
                    outlined
                    id="textarea"
                    v-model="form.manual_time_reason"
                    label="Reason"
                    rows="3"
                    max-rows="6"
                    maxlength="250"
                  ></v-textarea>
              </div>
            </v-col>
          </v-row>

          <!-- Add Manual Time Button -->
          <v-row class="text-center">
            <v-col cols="12">
              <v-btn
                class="addtime mb-1 mt-n3 subit-btn-add white--text mb-5"
                type="submit"
                color="#2758f6"
              >
                Add Time
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <!-- Reports Table  -->
        <div v-show="reportsTab == true">
          <v-data-table
            id="my-table"
            :empty-text="checkData"
            :items="manualList"
            :headers="fields"
            :items-per-page="5"
            class="elevation-1"
          >
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "AddManualTimeModal",
  data() {
    return {
      addManualTaskModal: false,
      addTimeTab: false,
      reportsTab: false,
      selectedTab: "Reports",
      reason_length_exceed: false,
      task: "",
      btn_spin: false,
      form: {
        minutes: 0,
        member_id: null,
        manual_time_reason: "",
      },
      selected_member: null,
      showLoader: false,
      manualList: [],
      fields: [
        {
          value: "assign_to",
          text: "Assign To",
        },
        {
          value: "assign_by",
          text: "Assign By",
        },
        {
          value: "manual_time",
          text: "Manual Time",
        },
        {
          value: "manual_time_reason",
          text: "Manual Time Reason",
        },
        {
          value: "created_date",
          text: "Created At",
        },
      ],
    };
  },
  mounted() {
    /**
     * Custom event for update
     * task data in modal
     */
    this.$root.$on("add-manual-time", (task) => {
      this.task = task;
      this.selectedTab = "Reports";
      this.addTimeTab = true;
      this.reportsTab = false;
      this.addManualTaskModal = true;
    });
  },
  computed: {
    checkData() {
      if (this.showLoader === false) {
        return "Loading....";
      } else {
        return "No data found";
      }
    },
  },
  methods: {
    /**
     * This function is resposible for
     * clear the data when modal is close
     */
    onHidden() {
      this.form.minutes = 0;
      this.form.member_id = null;
      this.form.manual_time_reason = "";
      this.manualList = [];
      this.reason_length_exceed = false;
    },
    /**
     * This function is resposible for
     * subminting add manual time form
     * and api call to update data
     */
    onSubmit() {
      if (!this.form.minutes) return;
      if (this.reason_length_exceed) return;
      let sent_member_id;
      if (this.form.member_id) {
        sent_member_id = this.form.member_id;
      } else {
        if (this.selected_member) {
          sent_member_id = this.selected_member;
        } else {
          let message = "Please Select Member To Update Time";
          this.$root.$emit("snack_bar", {
            show: true,
            message: message,
            snackbarColor: "red",
          });
          return;
        }
      }
      this.btn_spin = true;

      let data = {
        company_id: this.$route.params.id,
        task_id: this.task.id,
        minutes: this.form.minutes,
        member_id: sent_member_id,
        manual_time_reason: this.form.manual_time_reason,
      };
      this.$store
        .dispatch("custom/add_manual_time", data)
        .then((response) => {
          this.btn_spin = false;
          this.addManualTaskModal = false;
          this.form.manual_time_reason = "";
          if (response.data.error) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "red",
            });
          } else {
            this.$root.$emit("bv::hide::modal", "taskAddManualTime");
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.$root.$emit("task_list_update", true);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setTimeout(() => {
              this.btn_spin = false;
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.error_msg,
                snackbarColor: "red",
              });
            }, 500);
          } else {
            this.btn_spin = false;
            setTimeout(() => {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.message,
                snackbarColor: "red",
              });
            }, 500);
          }
        });
    },
    /**
     * This function is resposible for
     * getting reports table data
     */
    getManualTimeList() {
      let data = {
        company_id: this.$route.params.id,
        task_id: this.task.id,
      };
      this.$store
        .dispatch("custom/getManaulList", data)
        .then((response) => {
          this.showLoader = true;
          this.manualList = response.data.task;
        })
        .catch((error) => {
          console.log(error);
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.response.data.error_msg,
            snackbarColor: "red",
          });
        });
    },
  },
  watch: {
    "form.manual_time_reason": {
      handler: _.debounce(function () {
        if (this.form.manual_time_reason.length > 249) {
          this.reason_length_exceed = true;
        } else if (this.form.manual_time_reason.length <= 249) {
          this.reason_length_exceed = false;
        }
      }),
      deep: true,
    },
  },
};
</script>

<style scoped>
.text-label {
  font-size: 12px;
  color: #88a1ca;
  font-family: "Poppins";
  letter-spacing: normal;
}
.reason-text {
  font-size: 13px;
  height: 78px;
  width: 89%;
  border-radius: 3px;
  border: none !important;
  padding-left: 10px;
  background-color: #f8f9fb;
}
#modal-addOrg {
  overflow: hidden !important;
}
</style>
